import { Injectable } from '@angular/core';
import {
  Router, RouterStateSnapshot, ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { RIGHT_NAV_TRANSLATION_BASE_PATH } from '~core/components/right-nav-breadcrumb-constants';
import { TranslatePipe } from '~shared/pipes/translate.pipe';
import { AppState } from '~core/states/app/app.state';

@Injectable({
  providedIn: 'root'
})
export class ReportSettingsResolver  {

  constructor(
    private translate: TranslatePipe,
    private appState: AppState
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {    
    const translatePath = `${RIGHT_NAV_TRANSLATION_BASE_PATH}.DisplayName.Report Settings`;
    
    const translatedTitle = this.translate.transform(translatePath);

    this.appState.set('pageTitle', translatedTitle);
  }
}