import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { ReportFilesService } from '../service/report-files.service';
import { RIGHT_NAV_TRANSLATION_BASE_PATH } from '~core/components/right-nav-breadcrumb-constants';
import { TranslatePipe } from '~shared/pipes/translate.pipe';
import { AppState } from '~core/states/app/app.state';

export const reportFilesResolver: ResolveFn<any> = (route, state) => {
  const translate = inject(TranslatePipe);
  const appState = inject(AppState);

  const translatePath = `${RIGHT_NAV_TRANSLATION_BASE_PATH}.DisplayName.Report Files`;
      
  const translatedTitle = translate.transform(translatePath);
  
  appState.set('pageTitle', translatedTitle);
  return inject(ReportFilesService).getReportFiles('Facility');
};
