import { inject } from '@angular/core';
import type { ResolveFn } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { SafeTkTableColumn } from 'src/app/safetk-table/components/safetk-table/safetk-table.component';
import { RIGHT_NAV_TRANSLATION_BASE_PATH } from '~core/components/right-nav-breadcrumb-constants';
import { STIL_LanguageTranslationDataService } from '~shared/services/apiSTILLanguageTranslationController';
import { TranslationsState } from '~translations/state/translations.state';
import { TranslatePipe } from '~shared/pipes/translate.pipe';
import { AppState } from '~core/states/app/app.state';

export type TranslationsData = {
  columns: SafeTkTableColumn[];
  translations: any[];
};

export const translationsDataResolver: ResolveFn<TranslationsData> = async (route, state) => {

  const translationState = inject(TranslationsState);

  const translationSvc = inject(STIL_LanguageTranslationDataService);

  const translate = inject(TranslatePipe);
  const appState = inject(AppState);

  const translations = await firstValueFrom(translationSvc.getRecordsForParentIDWithoutCache(translationState.get('activeLanguage').ID));

  const translatePath = `${RIGHT_NAV_TRANSLATION_BASE_PATH}.DisplayName.Translations`;
      
  const translatedTitle = translate.transform(translatePath);

  appState.set('pageTitle', translatedTitle);

  const columns: SafeTkTableColumn[] = [
    {
      label: 'Translation Type',
      labelTooltip: `<b>The type of translation.</b>
      <br><small><b>*Casing is important for multi-word, settings based terms like permit type names*</b></small>
      <br>
      Typically the permit type name or admin feature name
      <br>ie. <code class="bg-slate-400 text-stilRed-200">LockoutTagoutPermit</code> or <code class="bg-slate-400 text-stilRed-200">Assets</code>.
      <br><br>
      <b>Setting a default control value</b><br>
      <small>All translation locations will default to this value if no more specific translation value is set</small>
      <br>
      Set this field to <code class="bg-slate-400 text-stilRed-200">DEFAULT_CONTROL_VALUE</code> to set a default control value.
      <br><br>
      <b>Using A Variant</b><br>
      <small>A variant will check for the variant translation, & fallback to the translation without the variant when not found</small>
      <br>
      Add <code class="bg-slate-400 text-stilRed-200">::<variant name></code> to the end of the translation type. ie <code class="bg-slate-400 text-stilRed-200">LockoutTagoutPermit::OP-01-GRP</code>
      `,
      name: 'itemType',
      editable: true,
      sortable: true,
      template: 'combobox',
      config: {
        controlType: 'combobox',
        filter: true,
        options: translations,
        referenceProp: 'itemType'
      },
      order: 1
    },
    {
      label: 'Feature Name',
      labelTooltip: `<b>The parent page/tab of the translation</b>
      <br><small><b>*Casing is important for multi-word settings based terms like tab names*</b></small>
      <br>
      Typically the page/tab name
      <br>ie. <code class="bg-slate-400 text-stilRed-200">HazardAnalysis</code> or <code class="bg-slate-400 text-stilRed-200">Tags</code>.
      `,
      name: 'formName',
      editable: true,
      sortable: true,
      template: 'combobox',
      config: {
        controlType: 'combobox',
        filter: true,
        options: translations,
        referenceProp: 'formName'
      },
      order: 2
    },
    {
      label: 'Translation Path',
      labelTooltip: `<b>The location of the translation on the page</b>
      <br><small><b>*Casing is important for multi-word, settings based terms like permit type names*</b></small>
      <br><br>
      <b>Deeply nested paths</b><br>
      If the translation is deeply nested, each part of the path can be separated by an underscore (_)
      <br>ie.
      <code class="bg-slate-400 text-stilRed-200">CategoryName_ChildName_ControlName</code>
      `,
      name: 'controlName',
      editable: true,
      sortable: true,
      template: 'combobox',
      config: {
        controlType: 'combobox',
        filter: true,
        options: translations,
        referenceProp: 'controlName'
      },
      order: 3
    },
    {
      label: 'Translation Key',
      labelTooltip: `<b>The key of the translation</b><br>
      <br><small><b>*Casing is important for multi-word settings based terms like columnnames*</b></small>
      Translations are actually processed behind the scenes as a path to a text value.<br>
      This field is the "key" to the text value<br>
      Given a path of <code class="bg-slate-400 text-stilRed-200">LockoutTagoutPermit::OP-01-GRP | Tags | Add_Button</code><br>
      A potentional translation key might be:<br>
      <code class="bg-slate-400 text-stilRed-200">Tooltip_Text</code><br>
      Or given a path of <code class="bg-slate-400 text-stilRed-200">LockoutTagoutPermit | Tags | Tag_Field</code><br>
      A potentional translation key might be:<br>
      <code class="bg-slate-400 text-stilRed-200">LockingDevice</code> or <code class="bg-slate-400 text-stilRed-200">Notes</code>
      `,
      name: 'defaultCaption',
      editable: true,
      sortable: true,
      config: {
        controlType: 'combobox',
        filter: true,
        options: translations,
        referenceProp: 'defaultCaption'
      },
      order: 4
    },
    {
      label: 'Translated Value',
      name: 'preferredCaption',
      editable: true,
      sortable: true,
      template: 'combobox',
      config: {
        controlType: 'combobox',
        filter: true,
        options: translations,
        referenceProp: 'preferredCaption'
      },
      order: 5
    }
  ];

  return {
    columns,
    translations
  };
};
